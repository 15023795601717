<template lang="pug">
div
  b-row.m-0.justify-content-center
    b-col.content-header(cols='9')
      h2.content-header-title.float-left.m-0.pr-1.mr-1
        | {{ $route.meta.pageTitle }}
      .content-header-search.mr-1
        h3.m-0 {{ deviceForm.label }}
      .content-header-actions.d-flex
        feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
  .content-loader-center.m-0.h-100(v-if='isLoadingDevice || isLoadingCustomer || isLoadingPool')
    .text-center.flex-center
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des d&eacute;tails de l'appareil...
  b-row.content-scrollable-sticky.m-0.h-100.justify-content-center(v-else)
    b-col.content-scrollable-sticky-main(cols='9')
      validation-observer(ref='formCreateDevice')
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-group(label='Nom *' label-for='label')
              validation-provider(#default='{ errors }' name='label' rules='required')
                b-form-input#city(v-model='deviceForm.label' :state='errors.length > 0 ? false : null')
                small.text-danger {{ errors[0] }}
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Marque *' label-for='brand')
              validation-provider(#default='{ errors }' name='brand' rules='required')
                v-select#coatingType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='deviceForm.brandId' :options='brandsList')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Type *' label-for='type')
              validation-provider(#default='{ errors }' name='type' rules='required')
                v-select#coatingType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='deviceForm.deviceTypeId' :options='deviceTypesList')
                small.text-danger {{ errors[0] }}
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-group(label='Date *' label-for='date')
              validation-provider(#default='{ errors }' name='date' rules='required')
                date-range-picker.w-100( :state='errors.length > 0 ? false : null' ref="picker" id="accountingExportDuration" :show-dropdowns="true" :single-date-picker="true" :show-week-numbers="true" :timePicker24Hour='true' :timePicker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="filterRange" :auto-apply="true" )
                small.text-danger {{ errors[0] }}
        b-row.my-0
          b-col.py-0.mb-1(cols="6")
            b-form-group(label='Client *' label-for='customer')
              validation-provider(#default='{ errors }' name='customer' rules='required')
                v-select#coatingType(:state='errors.length > 0 ? false : null' v-model='customerId' :filter="searchCustomer" label="firstName" :reduce='(type) => type.id' :options='customersList')
                  template(#option='{ firstName, lastName }')
                    | {{ firstName + ' ' + lastName }}
                  template(#selected-option='{ firstName, lastName }')
                    | {{ firstName + ' ' + lastName }}
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Bassin *' label-for='pool' v-if="customerId")
              validation-provider(#default='{ errors }' name='pool' rules='required')
                v-select#coatingType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='deviceForm.poolId' :options='filteredPools')
                small.text-danger {{ errors[0] }}
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-group(label='Commentaire *' label-for='label')
              vs-textarea(v-model='deviceForm.commentary' height='140px' style='min-height: 140px; font-size: 1rem')
        b-row.my-0
          b-col.py-0(cols='12')
            .d-flex.justify-content-between.mt-2(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem')
              .d-flex
                b-button(variant='outline-primary' @click='cancel()')
                  feather-icon(icon='ArrowLeftIcon')
                  | Annuler
                b-button.btn-icon.ml-2(v-if='deviceForm.id' variant='outline-danger' @click="archiveDeviceLocal(deviceForm.id, deviceForm.firstName + ' ' + deviceForm.lastName)")
                  feather-icon(icon='ArchiveIcon')
              b-button(variant='primary' @click='create')
                feather-icon(icon='SaveIcon' v-if='deviceForm.id')
                feather-icon(icon='PlusIcon' v-else)
                | {{ deviceForm.id ? "Modifier" : "Ajouter" }}
</template>
<script>
import vSelect from "vue-select";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

const moment = require('moment')
var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)
const config = {
  direction: 'ltr',
  format: 'dd/mm/yyyy HH:MM',
  separator: ' - ',
  applyLabel: 'Appliquer',
  cancelLabel: 'Annuler',
  weekLabel: 'S',
  customRangeLabel: 'Période personnalisée',
  daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  firstDay: 1
}

    let today = new Date()

    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)

    var defaultRange = {
      'Aujourd\'hui':[ today,today ],
      'Hier':[ yesterday, yesterday ],
      'Mois dernier': [
        new Date(
          dayjs()
            .subtract(1, 'month')
            .startOf('month')
            .hour(12)
        ),
        new Date(dayjs().subtract(1, 'month').endOf('month'))
      ],
      'Année dernière': [
        new Date(
          dayjs()
            .subtract(1, 'year')
            .startOf('year')
            .hour(12)
        ),
        new Date(
          dayjs()
            .subtract(1, 'year')
            .endOf('year')
        )
      ],
    }

export default{
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  name: "app",
  data() {
    return {
      displayAddress: false,
      displayGps: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
        addRemoveLinks: true,
      },
      removedFile: false,
      required,
      url,
      email,
      dataOrigine: {},
      customerId: null,
      config: config,
      defaultRange: defaultRange,
      startDateFiscalYear: {
        startDate: null,
        endDate: null
      },
      endDateFiscalYear: {
        startDate: null,
        endDate: null
      },
      filterRange:{},
    };
  },
  async created() {
    this.popupDeviceEvent(this.$route.params.id ? this.$route.params.id : "", this.$route.params.customerId ? this.$route.params.customerId : "")
    this.setInputDate()
    this.fetchCustomersList();
    this.fetchDeviceTypesList();
    this.fetchPoolsList()
    this.fetchBrandsList()
    
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    searchCustomer(options, search) {
      return options.filter(option => {
        const fullName = (option.firstName+' '+option.lastName).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, "").trim().toLowerCase()
        const searchNormalized = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, "").trim().toLowerCase()
        if (fullName.includes(searchNormalized)) {
          return option;
        }
      });
	},
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    async popupDeviceEvent(id) {
      if (id != "") {
        this.fetchDevice(id).then((res) => {
          this.deviceForm.brandId = res.brand.id
          this.deviceForm.deviceTypeId = res.deviceType.id
          this.fetchPool(res.poolId).then((res)=>{
            this.customerId = res.customerId
          })
          this.dataOrigine = JSON.stringify(this.deviceForm);
        })
      }  else {
        this.initializeForm();
      }
    },
    setInputDate(){ 
      if (this.deviceForm.id) {
        this.filterRange = {
          startDate: new Date(this.deviceForm.date),
          endDate: null
        }
      } else {
        this.filterRange = {
          startDate: new Date(),
          endDate: null
        }
      }
    },
    initializeForm() {
      this.deviceForm = {
        id: 0,
        label: "",
        deviceTypeId: null,
        date: new Date().toISOString(),
        brandId: null,
        poolId: null,
        commentary: "",
      };
      this.dataOrigine = JSON.stringify(this.deviceForm);
    },
    ...mapActions([
      "createDevice",
      "updateDevice",
      "fetchDevice",
      "archiveDevice",
      "fetchCustomersList",
      "fetchDeviceTypesList",
      "fetchPoolsList",
      "fetchPool",
      "fetchBrandsList"
    ]),
    create(e) {
      e.preventDefault();
      this.$refs.formCreateDevice
        .validate()
        .then((success1) => {
          const dateDevice = moment(this.filterRange.startDate).format('YYYY-MM-DD HH:mm:ss')
          if (success1) {
            if (this.deviceForm.id) this.updateDevice({id: this.deviceForm.id, label: this.deviceForm.label, date: dateDevice, brandId: this.deviceForm.brandId, deviceTypeId: this.deviceForm.deviceTypeId, poolId: this.deviceForm.poolId, commentary: this.deviceForm.commentary});
            else this.createDevice({label: this.deviceForm.label, date: dateDevice, brandId: this.deviceForm.brandId, deviceTypeId: this.deviceForm.deviceTypeId, poolId: this.deviceForm.poolId, commentary: this.deviceForm.commentary});
            this.$nextTick(() => {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close({ to: "/device" });
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    archiveDeviceLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveDevice(id);
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },

    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.deviceForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingDevice",
      "isLoadingPool",
      "isLoadingCustomer",
      "customersTypesList",
      "customersList",
      "deviceTypesList",
      "poolsList",
      "brandsList"
    ]),
    filteredPools(){
      let newArray = []
      if (this.customerId) {
        newArray = this.poolsList.filter(elem => elem.customerId === this.customerId)
      }
      return newArray
    },
    deviceForm: {
      get() {
        return this.$store.getters.device
      },
      set (value) {
        return this.$store.commit("SET_DEVICE", value)
      }
    }
  },
  watch: {
    "customerId": function(newVal, oldVal) {
      if (newVal !== oldVal && this.dataOrigine && this.dataOrigine.customerId !== newVal ) {
        this.deviceForm.poolId = null
      }
    },
    'filterRange': function(newVal){
      this.deviceForm.date = new Date(newVal.startDate).toISOString()
    },
    removedFile() {
      let that = this;
      setTimeout(function () {
        that.removedFile = false;
      }, 2000);
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    VSwatches,
    DateRangePicker,
  },
  directives: {
    mask,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
